<template>
  <b-tabs fill v-model="tabIndex">
    <b-tab title="Customer" @click="linkClass(0)" class="bg-gray">
      <slot name="header-tab"></slot>
      <CustomerDetailTab
        :form="form"
        :memberLevelList="memberLevelList"
        :FieldList="FieldList"
        @updateAddress="(val) => $emit('updateAddress', val)"
        :v="v"
        @openModalPoint="openModalPoint"
        :customerQuestionList="customerQuestionList"
        @updatePersonal="$emit('updatePersonal')"
        :isLoadingPersonal="isLoadingPersonal"
        :isLoadingUpdatePersonal="isLoadingUpdatePersonal"
        :isLoadingUpdateProfile="isLoadingUpdateProfile"
        :isConsentList="isConsentList"
        @fetch="(val) => $emit('fetch', val)"
        :noteList="noteList"
        @getData="$emit('getData')"
        @setForm="(val) => $emit('setForm', val)"
        :branchList="branchList"
        @updateNote="(note) => $emit('updateNote', note)"
    /></b-tab>
    <b-tab title="Purchase History" @click="linkClass(1)">
      <slot name="header-tab"></slot>
      <TableListHistory
        :fields="fieldsPurchase"
        :items="itemsPurchase"
        :rows="rowsPurchase"
        :isBusy="isBusyPurchase"
        :filter="filterPurchaseHistory"
        :pageOptions="pageOptions"
        :showing="showingPurchase"
        :showingTo="showingToPurchase"
        @page="setPagePurchase"
        :tab="1"
        @handleChangeTake="handleChangeTake"
      />
    </b-tab>
    <b-tab title="Reward Point History" @click="linkClass(2)">
      <slot name="header-tab"></slot>
      <TableListHistory
        :fields="fieldsPoint"
        :items="itemsPoint"
        :rows="rowsPoint"
        :form="form"
        :isBusy="isBusyPoint"
        :filter="filterPointHistory"
        :pageOptions="pageOptions"
        :showing="showingPoint"
        :showingTo="showingToPoint"
        :tab="2"
        @handleChangeTake="handleChangeTake"
        @page="setPagePurchase"
      />
    </b-tab>
    <b-tab title="Coupon History" @click="linkClass(3)">
      <slot name="header-tab"></slot>
      <TableCouponList
        :fields="fieldCoupon"
        :items="itemsCoupon"
        :rows="rowsCoupon"
        :isBusy="isBusyPoint"
        :filter="filterPointHistory"
        :pageOptions="pageOptions"
        :showing="showingPoint"
        :showingTo="showingToPoint"
        :tab="3"
        @handleChangeTake="handleChangeTake"
        @page="setPagePurchase"
      />
    </b-tab>
    <!-- <b-tab title="Record" @click="linkClass(4)">
      <TabNoteDetail :noteList="noteList" @updateNote="updateNote" />
    </b-tab> -->
    <b-tab title="Connect Channel" @click="linkClass(4)">
      <TabConnectChannel v-if="tabIndex == 4" />
    </b-tab>
    <b-tab
      title="Booking History"
      @click="linkClass(5)"
      v-if="$store.state.permission.Permission.response.includes(33)"
    >
      <slot name="header-tab"></slot>
      <TabBookingDetail v-if="tabIndex == 5" ref="booking" />
    </b-tab>
    <b-tab
      title="Member History"
      @click="linkClass(6)"
      v-if="$store.state.permission.Permission.response.includes(33)"
    >
      <slot name="header-tab"></slot>
      <TabMemberLog v-if="tabIndex == 6" ref="member-log" />
    </b-tab>
  </b-tabs>
</template>

<script>
import TableListHistory from "@/components/report/customer/detail/TableListHistory";
import TableCouponList from "@/components/report/customer/detail/TableCouponList";
import TabNoteDetail from "@/components/report/customer/detail/TabNoteDetail";
import TabBookingDetail from "@/components/report/customer/detail/TabBookingDetail";
import TabMemberLog from "@/components/report/customer/detail/TabMemberLog";
import TabConnectChannel from "@/components/report/customer/detail/TabConnectChannel";
import CustomerDetailTab from "@/components/report/customer/detail/CustomerDetailTab";
export default {
  components: {
    TableListHistory,
    TabNoteDetail,
    TabBookingDetail,
    TabMemberLog,
    TableCouponList,
    CustomerDetailTab,
    TabConnectChannel,
  },
  props: {
    fieldsPurchase: {
      required: true,
      type: Array,
    },
    itemsPurchase: {
      required: true,
      type: Array,
    },
    rowsPurchase: {
      required: true,
      type: Number,
    },
    isBusyPurchase: {
      required: true,
      type: Boolean,
    },
    filterPurchaseHistory: {
      required: true,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    showingPurchase: {
      required: true,
      type: Number,
    },
    showingToPurchase: {
      required: true,
      type: Number,
    },
    fieldsPoint: {
      required: true,
      type: Array,
    },
    itemsPoint: {
      required: true,
      type: Array,
    },
    rowsPoint: {
      required: true,
      type: Number,
    },
    isBusyPoint: {
      required: true,
      type: Boolean,
    },
    filterPointHistory: {
      required: true,
      type: Object,
    },
    showingPoint: {
      required: true,
      type: Number,
    },
    showingToPoint: {
      required: true,
      type: Number,
    },
    noteList: {
      required: true,
      type: Object,
    },
    fieldCoupon: {
      required: true,
      type: Array,
    },
    itemsCoupon: {
      required: true,
      type: Array,
    },
    rowsCoupon: {
      required: true,
      type: Number,
    },
    memberLevelList: {
      required: true,
      type: Array,
    },
    FieldList: {
      required: true,
      type: Array,
    },
    v: {
      required: true,
      type: Object,
    },
    customerQuestionList: {
      required: true,
      type: Array,
    },
    isLoadingPersonal: {
      required: true,
      type: Boolean,
    },
    isLoadingUpdatePersonal: {
      required: true,
      type: Boolean,
    },
    isLoadingUpdateProfile: {
      required: true,
      type: Boolean,
    },
    isConsentList: {
      required: true,
      type: Array,
    },
    form: {
      required: true,
      type: Object,
    },
    branchList: {
      required: false,
    },
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  created() {
    if (this.$route.query.tab) {
      this.tabIndex = this.$route.query.tab;
    }
  },
  methods: {
    openModalPoint(val) {
      this.$emit("openModalPoint", val);
    },
    setPagePurchase(filter) {
      this.$emit("setPagePurchase", filter);
    },
    linkClass(value) {
      this.$emit("selectTab", value);
    },
    handleChangeTake(filter) {
      this.$emit("handleChangeTake", filter);
    },
    updateNote(note) {
      this.$emit("updateNote", note);
    },
  },
};
</script>

<style scoped>
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
.bg-gray {
  background-color: #f8f8f8;
}
</style>
